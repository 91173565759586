import React, { useState, useEffect } from "react";
import {
  Paper,
  Button,
  CircularProgress,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Grid,
  Typography,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import moment from "moment-timezone";
import api from "./api";
import { Interviewee, Conversation } from "./SharedInterfaces";

const Queue: React.FC = () => {
  const [queueData, setQueueData] = useState<Interviewee[]>([]);

  const [openNewInterviewee, setOpenNewInterviewee] = useState(false);
  const [newInterviewee, setNewInterviewee] = useState({
    netid: "",
    full_name: "",
  });
  const [openIntervieweeModal, setOpenIntervieweeModal] = useState(false);
  const [intervieweeData, setIntervieweeData] = useState<{
    [key: string]: any;
  } | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState<Interviewee[]>([]);
  const [timeSinceCheckin, setTimeSinceCheckin] = useState<{
    [netid: string]: string;
  }>({});
  const [queueLoading, setQueueLoading] = useState(true);

  const calculateTimeSinceCheckin = (lastCheckin: string | null) => {
    if (lastCheckin === null) {
      return "";
    }

    const now = moment().utc();
    const checkinTime = moment.utc(lastCheckin);

    const differenceInSeconds = now.diff(checkinTime, "seconds");
    const minutes = Math.floor(differenceInSeconds / 60);
    const seconds = differenceInSeconds % 60;

    return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
  };

  const handleClickOpenNewInterviewee = () => {
    setOpenNewInterviewee(true);
  };

  const handleCloseNewInterviewee = () => {
    setOpenNewInterviewee(false);
  };

  const handleClickInterviewee = async (netid: string) => {
    try {
      const res = await api.get(`/admin/interviewee_info/${netid}`);
      setIntervieweeData(res.data);
      console.log(res.data);
      setOpenIntervieweeModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewInterviewee({
      ...newInterviewee,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      await api.post("/admin/add_interviewee", newInterviewee);
      fetchQueueData();
      handleCloseNewInterviewee();
    } catch (error) {
      console.error(error);
    }
  };

  const checkIn = async (interviewee_netid: string) => {
    try {
      const res = await api.post("/admin/checkin", {
        netid: interviewee_netid,
      });
      return res.data;
    } catch (error) {
      console.error(error);
    } finally {
      fetchQueueData();
    }
  };

  const checkOut = async (interviewee_netid: string) => {
    try {
      const res = await api.post("/admin/checkout", {
        netid: interviewee_netid,
      });
      return res.data;
    } catch (error) {
      console.error(error);
    } finally {
      fetchQueueData();
    }
  };

  const fetchQueueData = async () => {
    setQueueLoading(true);
    try {
      const res = await api.get("/admin/queue_and_interviewees");
      setQueueData(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setQueueLoading(false);
    }
  };

  // Function to handle the boost button click
  const handleBoost = async (interviewee_netid: string) => {
    try {
      const res = await api.post("/admin/boost", {
        netid: interviewee_netid,
      });
      return res.data;
    } catch (error) {
      console.error(error);
    } finally {
      fetchQueueData();
    }
  };

  // Fetch queue data on component mount
  useEffect(() => {
    fetchQueueData();
  }, []);

  useEffect(() => {
    setFilteredData(
      queueData.filter(
        (interviewee) =>
          interviewee.netid.toLowerCase().includes(searchTerm.toLowerCase()) ||
          interviewee.full_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [queueData, searchTerm]);

  useEffect(() => {
    const intervals = queueData.map((interviewee) => {
      const currentInterviewee = interviewee; // capture current interviewee in a closure
      return setInterval(() => {
        setTimeSinceCheckin((prevTimeSinceCheckin) => ({
          ...prevTimeSinceCheckin,
          [currentInterviewee.netid]: calculateTimeSinceCheckin(
            currentInterviewee.last_checkin
          ),
        }));
      }, 1000);
    });
    return () => intervals.forEach(clearInterval); // clear all intervals when the component unmounts
  }, [queueData]);

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: "10px" }}
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            autoFocus
            margin="dense"
            name="search"
            label="Search"
            type="text"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpenNewInterviewee}
          >
            Register new interviewee
          </Button>
        </Grid>
      </Grid>
      {queueLoading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>NetID</TableCell>
                <TableCell>Full Name</TableCell>
                <TableCell>Wait Time</TableCell>
                <TableCell>Convos</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((interviewee, index) => (
                <TableRow key={interviewee.netid}>
                  <TableCell>{interviewee.netid}</TableCell>
                  <TableCell
                    onClick={() => handleClickInterviewee(interviewee.netid)}
                  >
                    {interviewee.full_name}
                  </TableCell>
                  <TableCell>
                    {interviewee.status === "checked_in" ? (
                      <>
                        {timeSinceCheckin[interviewee.netid] && (
                          <Chip label={timeSinceCheckin[interviewee.netid]} />
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell>{interviewee.status}</TableCell>
                  <TableCell>{interviewee.status}</TableCell>
                  <TableCell>
                    {interviewee.status === "checked_in" && (
                      <>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleBoost(interviewee.netid)}
                          style={{ marginRight: "10px" }}
                        >
                          Boost
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => checkOut(interviewee.netid)}
                          style={{ marginLeft: "10px" }}
                        >
                          Check Out
                        </Button>
                      </>
                    )}
                    {interviewee.status === "checked_out" && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => checkIn(interviewee.netid)}
                      >
                        Check In
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Dialog open={openNewInterviewee} onClose={handleCloseNewInterviewee}>
            <DialogTitle>Register New Interviewee</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter the netid and full name of the new interviewee.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                name="netid"
                label="NetID"
                type="text"
                fullWidth
                value={newInterviewee.netid}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                name="full_name"
                label="Full Name"
                type="text"
                fullWidth
                value={newInterviewee.full_name}
                onChange={handleInputChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseNewInterviewee}>Cancel</Button>
              <Button onClick={handleSubmit}>Submit</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openIntervieweeModal}
            onClose={() => setOpenIntervieweeModal(false)}
          >
            <DialogTitle>Interviewee Details</DialogTitle>
            <DialogContent>
              {intervieweeData && (
                <>
                  <DialogContentText>
                    NetID: {intervieweeData.netid}
                  </DialogContentText>
                  <DialogContentText>
                    Full Name: {intervieweeData.full_name}
                  </DialogContentText>
                  {intervieweeData.last_checkin && (
                    <DialogContentText>
                      Last Checkin: {moment(intervieweeData.last_checkin).format('dddd, h:mm A')}
                    </DialogContentText>
                  )}
                  {intervieweeData.conversations && (
                    <>
                      {intervieweeData.conversations.map(
                        (conversation: Conversation) => (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            key={conversation.uuid}
                          >
                            <Card>
                              <CardHeader
                                title={conversation.interviewer_name}
                              />
                              <CardContent>
                                <Typography variant="body1" align="left">
                                  Start Time:{" "}
                                  {new Date(
                                    conversation.start_time
                                  ).toLocaleString("en-US", {
                                    weekday: "long",
                                    hour: "numeric",
                                    minute: "numeric",
                                    // timeZone: "America/New_York",
                                  })}
                                </Typography>
                                <Typography variant="body1" align="left">
                                  End Time:{" "}
                                  {conversation.end_time
                                    ? new Date(
                                        conversation.end_time
                                      ).toLocaleString("en-US", {
                                        weekday: "long",
                                        hour: "numeric",
                                        minute: "numeric",
                                        // timeZone: "America/New_York",
                                      })
                                    : "In Progress"}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        )
                      )}
                    </>
                  )}
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenIntervieweeModal(false)}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </TableContainer>
      )}
    </>
  );
};

export default Queue;
