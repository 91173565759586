import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import api from './api';
import Alert from '@mui/material/Alert';

const Auth: React.FC = () => {
  const navigate = useNavigate();
  const [netid, setNetid] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 5000); // 5000ms = 5s

      return () => {
        clearTimeout(timer);
      };
    }
  }, [error]);

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/simple_login?netid=${netid}`);
      if (response.data.role) {
        localStorage.setItem('netid', netid);
        localStorage.setItem('role', response.data.role);
        navigate(`/${response.data.role}`);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        setError("We do not see you registered for bicker at this time. If this is a mistake, please contact an officer.");
      } else {
        console.error("Error fetching login:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleNetidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedValue = event.target.value.replace(/\W/g, '').toLowerCase();
    setNetid(cleanedValue);
  };

  return (
    <Container maxWidth="xs">
      {error && 
        <Grid item>
          <Alert severity="error">{error}</Alert>
        </Grid>
      }
      <Grid container direction="column" justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
        <TextField 
          label="NetID" 
          variant="outlined" 
          value={netid} 
          onChange={handleNetidChange} 
          style={{ marginBottom: '20px', width: '100%' }}
        />
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleLogin} 
          disabled={isLoading}
          style={{ width: '100%' }}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Log in'}
        </Button>
      </Grid>
    </Container>
  );
  
    // useEffect(() => {
    //   const fetchLoginUrl = async () => {
    //     console.log("Fetching login URL...");
    //     const response = await fetch(`${BASE_URL}/login`);
    //     if (response.headers.get('content-type')?.includes('application/json')) {
    //       const data = await response.json();
    //       console.log("Response data: ", data);
    //       if (data.login_url) {
    //         console.log("Redirecting to login URL...");
    //         window.location.href = data.login_url;  // Redirect to the CAS login URL
    //       } else if (data.role) {
    //         console.log("Redirecting to user interface...");
    //         navigate(`/${data.role}`);  // Redirect to the user's interface
    //       }
    //     }
    //     setIsLoading(false);
    //   };
      
    //   const fetchUserRole = async () => {
    //     console.log("Fetching user role...");
    //     const response = await fetch(`${BASE_URL}/login?ticket=${searchParams.get('ticket')}`);
    //     if (response.headers.get('content-type')?.includes('application/json')) {
    //       const data = await response.json();
    //       console.log("Response data: ", data);
    //       if (data.role) {
    //         console.log("Redirecting to user interface...");
    //         navigate(`/${data.role}`);  // Redirect to the user's interface
    //       }
    //     }
    //     setIsLoading(false);
    //   };
  
    //   if (searchParams.has('ticket')) {
    //     console.log("Ticket found in query parameters...");
    //     fetchUserRole();  // If there's a ticket in the query parameters, fetch the user's role
    //   } else {
    //     console.log("No ticket found in query parameters...");
    //     fetchLoginUrl();  // If there's no ticket, fetch the CAS login URL
    //   }
    // }, [navigate, searchParams]);
};

export default Auth;