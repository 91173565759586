import React, { useEffect, useState } from "react";
import {
  Paper,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Interviewer } from "./SharedInterfaces";
import api from "./api";

const Interviewers: React.FC = () => {
  const [interviewers, setInterviewers] = useState<Interviewer[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredInterviewers, setFilteredInterviewers] = useState<Interviewer[]>([]);
  const [openNewInterviewer, setOpenNewInterviewer] = useState(false);
  const [newInterviewer, setNewInterviewer] = useState({
    netid: "",
    full_name: "",
  });

  const handleClickOpenNewInterviewer = () => {
    setOpenNewInterviewer(true);
  };

  const handleCloseNewInterviewer = () => {
    setOpenNewInterviewer(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewInterviewer({
      ...newInterviewer,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      await api.post("/admin/add_interviewer", newInterviewer);
      fetchInterviewers();
      handleCloseNewInterviewer();
    } catch (error) {
      console.error(error);
    }
  };

  const fetchInterviewers = async () => {
    const res = await api.get("/admin/interviewers");
    setInterviewers(res.data);
  };

  useEffect(() => {
    fetchInterviewers();
  }, []);

  useEffect(() => {
    setFilteredInterviewers(
      interviewers.filter(
        (interviewer) =>
          interviewer.netid.toLowerCase().includes(searchTerm.toLowerCase()) ||
          interviewer.full_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [interviewers, searchTerm]);

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: "10px" }}
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            autoFocus
            margin="dense"
            name="search"
            label="Search"
            type="text"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleClickOpenNewInterviewer}>
            Register new interviewer
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>NetID</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>Convos</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredInterviewers.map((interviewer) => (
              <TableRow key={interviewer.netid}>
                <TableCell>{interviewer.netid}</TableCell>
                <TableCell>{interviewer.full_name}</TableCell>
                <TableCell>not configured</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Dialog open={openNewInterviewer} onClose={handleCloseNewInterviewer}>
          <DialogTitle>Register New Interviewer</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter the netid and full name of the new interviewer.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="netid"
              label="NetID"
              type="text"
              fullWidth
              value={newInterviewer.netid}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="full_name"
              label="Full Name"
              type="text"
              fullWidth
              value={newInterviewer.full_name}
              onChange={handleInputChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseNewInterviewer}>Cancel</Button>
            <Button onClick={handleSubmit}>Submit</Button>
          </DialogActions>
        </Dialog>
      </TableContainer>
    </>
  );
};

export default Interviewers;