import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import Conversations from "./Conversations";
import Queue from "./Queue";
import Interviewers from "./Interviewers";

const AdminInterface: React.FC = () => {
  const [tabValue, setTabValue] = useState<number>(
    parseInt(localStorage.getItem("tabValue") || "0")
  );

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    localStorage.setItem("tabValue", newValue.toString());
    setTabValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Conversations" />
            <Tab label="Queue" />
            <Tab label="Interviewers" />
          </Tabs>
        </Box>
        {tabValue === 0 && <Conversations />}
        {tabValue === 1 && <Queue />}
        {tabValue === 2 && <Interviewers />}
      </Box>
    </div>
  );
};

export default AdminInterface;
