import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  const netid = localStorage.getItem("netid");
  const role = localStorage.getItem("role");

  // Set the headers
  config.headers.netid = netid;
  config.headers.role = role;

  return config;
});

export default api;
