import React, { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import api from "./api";

const IntervieweeInterface: React.FC = () => {
  const [queuePosition, setQueuePosition] = useState<number | null>(null);
  const [status, setStatus] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [numConversations, setNumConversations] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 5000); // 5000ms = 5s

      return () => {
        clearTimeout(timer);
      };
    }
  }, [error]);

  const getName = async () => {
    try {
      const response = await api.get("/interviewee/get_name");
      setName(response.data);
    } catch (error: any) {
      console.error(error);
      if (error.response) {
        setError(error.response.data.detail);
      } else {
        setError(error.message);
      }
    }
  };

  const getNumConversations = async () => {
    try {
      const response = await api.get("/interviewee/number_conversations");
      setNumConversations(response.data);
    } catch (error: any) {
      console.error(error);
      if (error.response) {
        setError(error.response.data.detail);
      } else {
        setError(error.message);
      }
    }
  };

  const checkIn = async () => {
    setIsLoading(true);
    try {
      const response = await api.post("/interviewee/checkin");
      setQueuePosition(response.data.queue_position);
      setStatus(response.data.status);
    } catch (error: any) {
      console.error(error);
      if (error.response) {
        setError(error.response.data.detail);
      } else {
        setError(error.message);
      }
    }
    setIsLoading(false);
  };

  const checkOut = async () => {
    setIsLoading(true);
    try {
      const response = await api.post("/interviewee/checkout");
      setQueuePosition(response.data.queue_position);
      setStatus(response.data.status);
    } catch (error: any) {
      console.error(error);
      if (error.response) {
        setError(error.response.data.detail);
      } else {
        setError(error.message);
      }
    }
    setIsLoading(false);
  };

  const getStatus = async () => {
    try {
      console.log("Getting status...");
      const response = await api.get("/interviewee/status");
      console.log("Response data: ", response.data);
      setStatus(response.data);
    } catch (error: any) {
      console.error(error);
      if (error.response) {
        setError(error.response.data.detail);
      } else {
        setError(error.message);
      }
    }
  };

  const getQueuePosition = async () => {
    try {
      console.log("Getting queue position...");
      const response = await api.get("/interviewee/queue_position");
      console.log("Response data: ", response.data);
      setQueuePosition(response.data);
    } catch (error: any) {
      console.error(error);
      if (error.response) {
        setError(error.response.data.detail);
      } else {
        setError(error.message);
      }
    }
  };

  useEffect(() => {
    getStatus();
    getQueuePosition();
    getName();
    getNumConversations();

    const interval = setInterval(() => {
      getStatus();
      getQueuePosition();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const netid = localStorage.getItem("netid"); // replace 'netId' with the actual key

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      {error && <Alert severity="error">{error}</Alert>}
      <Grid item xs={12} style={{ marginBottom: "50px" }}>
        <Typography variant="h5" component="div" gutterBottom>
          Welcome, {name}, to Ivy Bicker. When you are at the club and ready to
          have a conversation, check in. When you leave, please don't forget to
          check out. We look forward to getting to know you!
        </Typography>
      </Grid>
      {status === "checked_in" && (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <Typography variant="h5" component="div" gutterBottom>
            Queue position:
          </Typography>
          <Paper elevation={3} style={{ padding: "0px 10px" }}>
            <Typography variant="h6" component="div" gutterBottom>
              {queuePosition}
            </Typography>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12} style={{ marginBottom: "15px" }}>
        {status === "in_conversation" ? (
          <Typography variant="h6" color="secondary">
            In Conversation...
          </Typography>
        ) : status === "checked_in" ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={async () => {
              await checkOut();
              await getQueuePosition();
            }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Check Out"}
          </Button>
        ) : status === "requested" ? (
          <Typography variant="h6" color="secondary">
            Conversation starting soon. Listen for your name to be called.
          </Typography>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              await checkIn();
              await getQueuePosition();
            }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Check In"}
          </Button>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" gutterBottom>
          Number of conversations: {numConversations}/10
        </Typography>
      </Grid>
    </Grid>
  );
};

export default IntervieweeInterface;
