import React, { useState, useEffect } from 'react';
import api from './api';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import { Conversation } from './SharedInterfaces';


const InterviewerInterface = () => {
  const [loading, setLoading] = useState(false);
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [openProceedModal, setOpenProceedModal] = useState(false);
  const [interviewee, setInterviewee] = useState<any>(null);
  const [conversationsList, setConversationsList] = useState<Conversation[]>([]);
  const [inConversation, setInConversation] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 5000); // 5000ms = 5s

      return () => {
        clearTimeout(timer);
      };
    }
  }, [error]);

  useEffect(() => {
    getConversationsList();
  }, []);

  useEffect(() => {
    checkIfInConversation();
  }, []);

  useEffect(() => {
    conversationsList.map((conversation, index) => {
      console.log("Conversation id: ", conversation.uuid);
    });
  }, [conversationsList]);

  const getConversationsList = async () => {
    try {
      const response = await api.get('/interviewer/get_conversations');
      const data = await response.data;
      setConversationsList(data);
      console.log("Conversations list: ", data);
    } catch (error: any) {
      console.log("Error fetching conversation list")
      if (error.response) {
        setError(error.response.data.detail);
      } else {
        setError(error.message);
      }
    }
  };

  const requestConversation = async () => {
    setLoading(true);
    try {
      const response = await api.post('/interviewer/request_conversation');
      setInterviewee(response.data);
      setOpenRequestModal(true);
    } catch (error: any) {
      if (error.response) {
        setError(error.response.data.detail);
      } else {
        setError(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSkip = async () => {
    setLoading(true);
    try {
      await api.post('/interviewer/skip');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setOpenRequestModal(false);
    }
  };
  
  const handleProceed = async () => {
    setOpenRequestModal(false);
    setOpenProceedModal(true);
  };
  
  const handleNotPresent = async () => {
    setLoading(true);
    try {
      await api.post('/interviewer/checkout_interviewee');
    } catch (error: any) {
      if (error.response) {
        setError(error.response.data.detail);
      } else {
        setError(error.message);
      }
    } finally {
      setLoading(false);
    }
    // Close the modal
    setOpenProceedModal(false);
  };
  
  const handleBeginConversation = async () => {
    setLoading(true);
    try {
      await api.post('/interviewer/start_conversation');
    } catch (error: any) {
      if (error.response) {
        setError(error.response.data.detail);
      } else {
        setError(error.message);
      }
    } finally {
      setLoading(false);
      checkIfInConversation();
    }
    // Close the modal
    setOpenProceedModal(false);
  };

  const handleEndConversation = async () => {
    setLoading(true);
    try {
      await api.post('/interviewer/end_conversation');
    } catch (error: any) {
      if (error.response) {
        setError(error.response.data.detail);
      } else {
        setError(error.message);
      }
    } finally {
      setLoading(false);
      checkIfInConversation();
      getConversationsList();
    }
  };

  // Function to check if the interviewer is in a conversation
  const checkIfInConversation = async () => {
    try {
      const response = await api.get('/interviewer/is_in_conversation');
      const data = await response.data;
      setInConversation(data);
      console.log("In conversation: ", data);
    } catch (error: any) {
      console.log("Error checking if in conversation")
      if (error.response) {
        setError(error.response.data.detail);
      } else {
        setError(error.message);
      }
    }
  };

  return (
    <div>
      <Container>
        <Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
          <Grid item style={{ marginBottom: '50px' }}>
            <Typography variant="h5" component="div" gutterBottom>
              Welcome! When you are ready to bicker someone, request a conversation below.
            </Typography>
          </Grid>
          {error && 
            <Grid item>
              <Alert severity="error">{error}</Alert>
            </Grid>
          }
          <Grid item style={{ marginBottom: '30px' }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" color="primary" onClick={requestConversation}>
                Request Conversation
              </Button>
            )}
          </Grid>

          <Grid item>
            <Typography variant="h6" component="div" gutterBottom style={{ fontWeight: 'bold', marginBottom: '30px' }}>
              Previous conversations:
            </Typography>
          </Grid>
          <Grid container spacing={2} justifyContent="center">
            {conversationsList.map((conversation, index) => (
              <Grid item xs={12} sm={6} md={4} key={conversation.uuid}>
                <Card>
                  <CardHeader title={conversation.interviewee_name} />
                  <CardContent>
                    <Typography variant="body1" align='left'>
                      Start Time: {new Date(conversation.start_time).toLocaleString("en-US", {weekday: 'long', hour: 'numeric', minute: 'numeric', timeZone: 'America/New_York'})}
                    </Typography>
                    <Typography variant="body1" align='left'>
                      End Time: {conversation.end_time ? new Date(conversation.end_time).toLocaleString("en-US", {weekday: 'long', hour: 'numeric', minute: 'numeric', timeZone: 'America/New_York'}) : 'In Progress'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>

      <Dialog open={openRequestModal} onClose={() => setOpenRequestModal(false)}>
        <DialogTitle>Potential Conversation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {interviewee ? `Can you bicker ${interviewee.full_name}?` : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSkip} color="secondary">
            No, I know them
          </Button>
          <Button onClick={handleProceed} color="primary">
            Proceed
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openProceedModal} onClose={() => setOpenProceedModal(false)}>
        <DialogTitle>Find Your Bickeree</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Call out their name from the staircase, and then answer the following question:
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNotPresent} color="secondary">
            They are not present
          </Button>
          <Button onClick={handleBeginConversation} color="primary">
            I see them, begin conversation
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={inConversation} onClose={() => {}}>
        <DialogTitle>Conversation in Progress</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are currently in a conversation. Don't ask any weird questions, please.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEndConversation} color="primary">
            End Conversation
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InterviewerInterface;