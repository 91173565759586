import React from 'react';
import { Routes, Route } from 'react-router-dom';
import InterviewerInterface from './InterviewerInterface';
import IntervieweeInterface from './IntervieweeInterface';
import AdminInterface from './AdminInterface';
import Auth from './Auth';
import { AppBar, Toolbar, Typography } from '@mui/material';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Welcome to Ivy Bicker.
          </Typography>
        </Toolbar>
      </AppBar>
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route path="/interviewer" element={<InterviewerInterface />} />
        <Route path="/interviewee" element={<IntervieweeInterface />} />
        <Route path="/admin" element={<AdminInterface />} />
      </Routes>
    </div>
  );
}

export default App;