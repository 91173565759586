// Conversations.tsx
import React, { useEffect, useState } from "react";
import { Conversation } from "./SharedInterfaces";
import {
  Button,
  CircularProgress,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import api from "./api";

const Conversations: React.FC = () => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [potentialConversations, setPotentialConversations] = useState<
    Conversation[]
  >([]);
  const [enabled, setEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchConversations();
    fetchPotentialConversations();
    fetchNewConversationsAllowedStatus();
  }, []);

  const fetchNewConversationsAllowedStatus = async () => {
    setLoading(true);
    try {
      const res = await api.get("/admin/new_conversations_allowed");
      setEnabled(res.data.status);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const toggleInterviews = async () => {
    setLoading(true);
    try {
      if (enabled) {
        await blockNewConversations();
      } else {
        await allowNewConversations();
      }
      setEnabled(!enabled);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      fetchNewConversationsAllowedStatus();
    }
  };

  const allowNewConversations = async () => {
    try {
      const res = await api.post("/admin/allow_new_conversations");
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  const blockNewConversations = async () => {
    try {
      const res = await api.post("/admin/block_new_conversations");
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchConversations = async () => {
    try {
      const res = await api.get("/admin/conversations");
      setConversations(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPotentialConversations = async () => {
    try {
      const res = await api.get("/admin/potential_conversations");
      setPotentialConversations(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const startConversation = async (
    interviewer: string,
    interviewee: string
  ) => {
    try {
      const res = await api.post("/admin/start_conversation", {
        interviewer,
        interviewee,
      });
      return res.data;
    } catch (error) {
      console.error(error);
    } finally {
      fetchConversations();
      fetchPotentialConversations();
    }
  };

  const endConversation = async (conversationId: string) => {
    try {
      console.log(conversationId);
      const res = await api.post("/admin/end_conversation", {uuid: conversationId});
      return res.data;
    } catch (error) {
      console.error(error);
    } finally {
      fetchConversations();
    }
  };

  return (
    <div>
      <Typography variant="body1">Enable/Disable New Interviews</Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Switch checked={enabled} onChange={toggleInterviews} color="primary" />
      )}
      <Typography variant="h6">Potential Conversations</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Interviewer</TableCell>
              <TableCell>Interviewee</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {potentialConversations.map((conversation) => (
              <TableRow key={conversation.uuid}>
                <TableCell>
                  {conversation.interviewer_name} ({conversation.interviewer})
                </TableCell>
                <TableCell>
                  {conversation.interviewee_name} ({conversation.interviewee})
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      startConversation(
                        conversation.interviewer,
                        conversation.interviewee
                      )
                    }
                  >
                    Start Conversation
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h6">Current Conversations</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Interviewer</TableCell>
              <TableCell>Interviewee</TableCell>
              <TableCell>Start Time</TableCell>
              <TableCell>End Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {conversations.map((conversation) => (
              <TableRow key={conversation.uuid}>
                <TableCell>
                  {conversation.interviewer_name} ({conversation.interviewer})
                </TableCell>
                <TableCell>
                  {conversation.interviewee_name} ({conversation.interviewee})
                </TableCell>
                <TableCell>
                  {new Date(conversation.start_time).toLocaleString("en-US", {
                    weekday: "long",
                    hour: "numeric",
                    minute: "numeric",
                    timeZone: "America/New_York",
                  })}
                </TableCell>
                <TableCell>
                  {conversation.end_time ? (
                    new Date(conversation.end_time).toLocaleString("en-US", {
                      weekday: "long",
                      hour: "numeric",
                      minute: "numeric",
                      timeZone: "America/New_York",
                    })
                  ) : (
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "red", color: "white" }}
                      onClick={() => endConversation(conversation.uuid)}
                    >
                      End Conversation
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Conversations;
